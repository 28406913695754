import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import './Registration.css';

const RegistrationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const register = async () => {
    try {
      await Auth.signUp({
        username: email,
        password: password,
      });
      setMessage('Registration successful! Check your email for a confirmation code.');
      setTimeout(() => {
        navigate('/login'); // Redirect to login page after 5 seconds
      }, 5000);
    } catch (error) {
      console.log('Error signing up', error);
      setMessage('Error signing up, please try again');
    }
  };

  return (
    <div className="registration-container">
      <input
        type="email"
        placeholder="Email"
        className="registration-input"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        className="registration-input"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="registration-button" onClick={register}>
        Register
      </button>
      {message && <p className="registration-message">{message}</p>}
    </div>
  );
};

export default RegistrationPage;
