/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:aac2b2b1-8a34-426b-bb8c-5df5d2b979bb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mAEPP7OsV",
    "aws_user_pools_web_client_id": "516pu8gk09mhdf8vdig1ku8aji",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cowtowntapfrontend7c2148a1458147d48cece5c3285fd182707-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
