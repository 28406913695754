import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import './Home.css';
import logo from './images/cowtowntap.jpg';
import myImage from './images/rustys.jpg';
import HamburgerMenu from './HamburgerMenu.js';
import AboutUs from './AboutUs.js';

function Home({ isLoggedIn, onLogout }) {  // onLogout is passed from App.js

  useEffect(() => {
    async function fetchUserAndCredentials() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const creds = await Auth.currentUserCredentials();
        console.log('User: ', user);
        console.log('Credentials: ', creds);
      } catch (error) {
        console.error('User is not authenticated');
      }
    }
    fetchUserAndCredentials();
  }, []);
  
  return (
    <div className="Home">
      <HamburgerMenu isLoggedIn={isLoggedIn} onLogout={onLogout}/>
      <div className="header">
        <img src={logo} className="logo" alt="logo" />
        <img src={myImage} className="image" alt="rustys" />
      </div>
      <div className="about-us">
        <AboutUs />
      </div>
    </div>
  );
}

export default Home;
