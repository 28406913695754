import React, { useState, useEffect } from 'react';
import { Amplify, Storage, withSSRContext } from 'aws-amplify';
import awsconfig from './aws-exports.js';
import HamburgerMenu from './HamburgerMenu.js';
import ImageUpload from './ImageUpload.js';
import './Gallery.css';

Amplify.configure(awsconfig);

function useImageUrl(key) {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      const imageUrl = await Storage.get(key, { expires: 3600 });
      setUrl(imageUrl);
    };

    fetchUrl();
  }, [key]);

  return url;
}

function GalleryImage({ image, index, userGroups, handleDeleteImage, openModal }) {
  const imageUrl = useImageUrl(image.key);
  
  return (
    <div className="gallery-image-container" key={index}>
      <img 
        className="gallery-image" 
        src={imageUrl} // use the URL from the custom hook
        alt={`gallery-${index}`} 
        onClick={() => openModal(image)} 
      />
      {userGroups.includes("GalleryAdmins") && (
        <button className="delete-button" onClick={() => handleDeleteImage(image.key)}>
          Delete
        </button>
      )}
    </div>
  );
}


function Gallery({ isLoggedIn, onLogout }) {
  const [images, setImages] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  
  
  useEffect(() => {
    let isMounted = true;

    async function checkUserGroups() {
      const { Auth } = withSSRContext();
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('User: ', user);
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        console.log('User groups:', groups);
        if (isMounted) {
          setUserGroups(groups || []);
          setIsAdminLoggedIn(groups.includes("GalleryAdmins")); // set isAdminLoggedIn
        }
      } catch (error) {
        console.log("User is not authenticated");
        if (isMounted) {
          setUserGroups([]);
          setIsAdminLoggedIn(false); // reset isAdminLoggedIn if user is not authenticated
        }
      }
    }

    async function fetchImages() {
      try {
        const response = await Storage.list('');
        console.log('Storage.list output:', response);
          
        const keys = response.results;
  
        if (!Array.isArray(keys)) {
          console.error('Error fetching images: keys is not an array');
          return [];
        }

        const imageKeys = keys
          .filter(
            (key) =>
              key.key.endsWith('.jpg') || key.key.endsWith('.jpeg') || key.key.endsWith('.png')
          )
          .map(({ key }) => key);
    
        console.log('Image keys:', imageKeys);
  
        return imageKeys;
      } catch (error) {
        console.error("Error fetching images: ", error);
        return [];
      }
    }

    checkUserGroups();
    fetchImages().then(fetchedKeys  => {
      if (isMounted) {
        setImages(fetchedKeys.map(key => ({ key })));
      }
    });

    return () => { isMounted = false; }
  }, []);
  
  const handleDeleteImage = async (imageKey) => {
    console.log('handleDeleteImage called with key:', imageKey); // NEW: log when function is called
  
    try {
      await Storage.remove(imageKey);
      console.log('Image deleted successfully:', imageKey);
  
      console.log('Images before:', images); // NEW: log state before updating
      setImages(prevImages => {
        const updatedImages = prevImages.filter(image => image.key !== imageKey);
        console.log('Images after:', updatedImages); // NEW: log state after updating
        return updatedImages;
      });
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };
  
  const currentImageUrl = useImageUrl(currentImage ? currentImage.key : null);
  
  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className="gallery-container">
      <HamburgerMenu isLoggedIn={isLoggedIn} onLogout={onLogout} />
      <div className="gallery-grid">
        {images.length > 0 ? (
          images.map((image, index) => (
            <GalleryImage
              key={index}
              image={image}
              index={index}
              userGroups={userGroups}
              handleDeleteImage={handleDeleteImage}
              openModal={openModal}
            />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {isAdminLoggedIn && <ImageUpload />}
      {isModalOpen && currentImage && (
        <div className="modal">
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={currentImageUrl} alt="modal"/>
        </div>
      )}
    </div>
  );
}

export default Gallery;
