import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const login = async () => {
    try {
      await Auth.signIn(email, password);
      const user = await Auth.currentAuthenticatedUser();
      const creds = await Auth.currentUserCredentials();
      console.log('User: ', user);
      console.log('Credentials: ', creds);
      setMessage('Login successful!');
      setTimeout(() => {
        navigate(location.state?.from || '/', { replace: true });
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log('Error logging in', error);
      setMessage(`Error logging in: ${error.message}`);
    }
  };

  return (
    <div className="login-container">
      <input
        type="email"
        placeholder="Email"
        className="login-input"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        className="login-input"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="login-button" onClick={login}>
        Login
      </button>
      {message && <p className="login-message">{message}</p>}
    </div>
  );
};

export default LoginPage;