import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div>
      <h2>About Cowtown TAP</h2>
      <p>
        Welcome to Cowtown TAP Pool League! We are a community of billiards enthusiasts who are passionate about playing and promoting the sport. Our league was established in 2017 with the goal of providing a fun, competitive, and friendly environment for players of all levels.
        <br/><br/>
        We are proud to offer a variety of leagues, tournaments, and events throughout the year, with opportunities for players to compete at the local, regional, and national levels. We also provide a platform for players to connect, learn from each other, and build friendships that last a lifetime.
        <br/><br/>
        At Cowtown TAP Pool League, we believe that everyone should have the opportunity to enjoy and excel at billiards. We are committed to promoting the sport and supporting its growth, both locally and nationally. Whether you're a seasoned player or just starting out, we invite you to join us and be a part of our community.
        <br/><br/>
        Thank you for your interest in Cowtown TAP Pool League, and we look forward to seeing you on the table!
      </p>
    </div>
  );
}

export default AboutUs;