import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './Home.js';
import Gallery from './Gallery.js';
import Login from './Login.js';
import Registration from './Registration.js';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports.js';

Amplify.configure(awsconfig);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Check the login status when the app starts
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }, []); // Empty array means this effect runs once on mount

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setTimeout(() => {
      navigate(location.state?.from || '/', { replace: true });
    }, 1000);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setIsLoggedIn(false);
      setTimeout(() => {
        navigate(location.state?.from || '/', { replace: true });
      }, 1000);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} onLogout={handleLogout} />} />
        <Route path="/gallery" element={<Gallery isLoggedIn={isLoggedIn} onLogout={handleLogout} />} />
        <Route
          path="/login"
          element={<Login onLoginSuccess={handleLoginSuccess} />}
        />
        <Route path="/register" element={<Registration />} />
        <Route
          path="/logout"
          element={handleLogout && <Navigate to="/" replace />}
        />
      </Routes>
    </div>
  );
}

export default App;
