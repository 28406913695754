import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HamburgerMenu.css';

const HamburgerMenu = ({ isLoggedIn, onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (path) => {
    if (path === '/login') {
      const currentLocation = window.location.pathname; // Get the current location
      navigate(path, { state: { from: currentLocation } }); // Pass the current location as state
    } else {
      navigate(path);
    }
    setIsMenuOpen(false);
  };

  return (
    <div>
      <button className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      {isMenuOpen && (
        <div className="menu-overlay">
          <ul className="menu-items">
            <li onClick={() => handleMenuItemClick('/')}>Home</li>
            <li onClick={() => handleMenuItemClick('/gallery')}>Gallery</li>
            {isLoggedIn ? (
              <li onClick={() => {console.log('Logout clicked'); onLogout();}}>Logout</li>
            ) : (
              <>
                <li onClick={() => handleMenuItemClick('/login')}>Login</li>
                <li onClick={() => handleMenuItemClick('/register')}>Register</li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;