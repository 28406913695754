import React, { useState, useEffect } from 'react';
import { Auth, Storage } from 'aws-amplify';
import './ImageUpload.css'; // import the CSS module

const ImageUpload = () => {
  const [file, setFile] = useState(null);
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUser(userInfo);
      } catch (err) {
        console.error(err);
      }
    }
    fetchUser();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log('Selected file: ', selectedFile);
    setFile(selectedFile);
  }

  const uploadImage = async () => {
    if (!file) {
      console.log('No file selected for upload');
      return;
    }
    
    console.log('Preparing to upload: ', file);
    
    const uniqueFileName = `${Date.now()}-${file.name}`;
    
    try {
      const result = await Storage.put(uniqueFileName, file);
      console.log('Successfully uploaded file: ', result);
      window.location.reload(); // refresh to show new image
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  }

  return (
    <div className="upload-container">
      <input className="file-input" type="file" accept="image/png, image/jpeg" onChange={handleFileChange} />
      <button className="upload-button" onClick={uploadImage}>Upload</button>
      {user && (
        <div>
          <h2>User Info:</h2>
          <p>Username: {user.username}</p>
          <p>Groups: {user.signInUserSession.idToken.payload['cognito:groups'] ? user.signInUserSession.idToken.payload['cognito:groups'].join(', ') : "No groups"}</p>
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
